* {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: 'Raleway', sans-serif
}

.App-logo {
  height: 40vmin;
}

.App-heading {
  background-color: #2c003e;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  padding: 0px;
  color: white;
}


.particle-container {
  width: 100%;
  height: 100%;
  margin: 0px;
}

p {
  margin: 1px
}



.name {
  color: #51dacf
}


footer {
  height: 50px;
  background-color: #2c003e;
  color: white;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  padding-top: 30px;
}

footer a{
  color: white;
  text-decoration: none;
  transition: 0.25s ease-in-out;
  margin: auto;
  padding: auto;
  display: block;
}

footer a:hover {
  color: #51dacf
}

