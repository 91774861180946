#contact {
    overflow: hidden;
    padding: 60px 60px 20px;
}

.information {
    margin: auto;
    width: 60%;
    justify-content: space-evenly;
    text-align: center;
}

.information p{
    margin: 40px;
    line-height: 1.6;
}

.information p span {
    color: #51dacf;
}

.email-button span:after {
    content: '\21d2';
}

svg {
    width: 40px;
    height: 40px;
    margin: 30px 10px;
    fill: #000000;
    transition: 0.2s ease-in-out;
}

svg:hover {
    fill: #51dacf;
}