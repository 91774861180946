
.navbar-fixed {
    top: 0;
    position: sticky;
    overflow: hidden;
    width: 100%;
    z-index: 100;
}


ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #282c34;
  }
  
li {
    float: left;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 15px 10px 16px 50px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

li a:hover {
    color: #51dacf;

}